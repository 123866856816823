<template>
    <a-drawer
        title="Добавить новость"
        placement="right"
        :width="isMobile ? '100%' : 700"
        class="news_drawer"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="visible = false">
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules">
            <a-form-model-item 
                ref="title" 
                label="Название" 
                prop="title">
                <a-input v-model="form.title" size="large" />
            </a-form-model-item>
            <a-form-model-item
                ref="category"
                label="Выберите категорию"
                prop="category">
                <a-select v-model="form.category" name="select" id="select" size="large">
                    <a-select-option v-for="item in categoryOptions" :key="item.id" :value="item.code">{{ item.string_view }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item ref="image" label="Добавить изображение" prop="image" class="w-full">
                <div class="flex items-center w-64">
                    <label for="image_upload" class="ant-input ant-input-lg flex items-center truncate cursor-pointer">
                        <template v-if="customFile && form.image">
                            {{ form.image.name }}.{{ form.image.extension }}
                        </template>
                        <template v-else>
                            <a-spin :spinning="fileLoading" size="small">
                                <div class="flex items-center blue_color">
                                    <i class="fi fi-rr-cloud-upload-alt"></i>
                                    <span class="ml-2">Нажмите чтобы загрузить</span>
                                </div>
                            </a-spin>
                        </template>
                    </label>
                    <a-button v-if="form.image" type="ui" size="large" class="ml-1" ghost flaticon icon="fi-rr-trash" @click="clearFile()"/>
                </div>
                <input
                    type="file"
                    id="image_upload"
                    style="display:none;"
                    ref="image_upload"
                    v-on:change="handleFileChange"
                    accept=".jpeg, .jpg, .png" />
            </a-form-model-item>
            <a-form-model-item 
                ref="content" 
                label="Описание" 
                prop="content">
                <component
                    :is="ckEditor"
                    :key="edit || visible"
                    v-model="form.content" />
            </a-form-model-item>
            <a-form-model-item 
                ref="is_important" 
                label="Важная новость" 
                prop="is_important">
                <a-switch v-model="form.is_important" />
            </a-form-model-item>
            <a-button type="primary" size="large" :loading="loading" @click="onSubmit()">
                {{ edit ? 'Сохранить' : 'Опубликовать' }}
            </a-button>
            <a-button type="secondary" size="large" class="ml-2.5" @click="visible = false">
                Отменить
            </a-button>
        </a-form-model>
    </a-drawer>
</template>

<script>
import Ckeditor from '@apps/CKEditor'
import eventBus from "@/utils/eventBus"
export default {
    components: {
        Ckeditor
    },
    props: {
        resetList: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        ckEditor() {
            if(this.visible)
                return () => import('@apps/CKEditor')
            else
                return null
        },
        prepareFormData() {
            const formData = {...this.form};
            if (formData.image) {
                formData.image = formData.image.id; // Передаем только ID изображения
            }
            return formData;
        },
    },
    data() {
        return {
            visible: false,
            edit: false,
            loading: false,
            customFile: false,
            categoryOptions: [],
            fileLoading: false,
            form: {
                title: '',
                content: '',
                is_important: false,
                related_object: null,
                image: null,
                category: ''
            },
            rules: {
                title: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur' }
                ],
                content: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur' }
                ],
                category: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur'}
                ],
                image: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur'}
                ]
            }
        }
    },
    created() {
        this.getSelectItems()
    },
    methods: {
        afterVisibleChange(vis) {
            if(!vis) {
                this.edit = false
                this.form = {
                    title: '',
                    content: '',
                    is_important: false,
                    related_object: null,
                    image: null,
                    category: null
                }
            }
        },
        openDrawer() {
            this.visible = true
        },
        openDrawerEdit(editData) {
            this.visible = true
            this.edit = true
            const formData = {...editData}
            if(formData.category?.code)
                formData.category = formData.category.code
            if(formData.image?.id)
                this.customFile = true
            this.form = formData
        },
        async getSelectItems() {
            const formData = {...this.form}
            try {
                this.loading = true
                const response = await this.$http.get('app_info/select_list/?model=bpms_common.NewsCategoryModel', formData);
                this.categoryOptions = response.data.selectList;
            } 
            catch(e) {
                console.error('Возникла ошибка:', e);
            }
            finally {
                this.loading = false;
            }
        },
        updateList() {
            eventBus.$emit('update_front_news_list')
        },
        onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    const formData = {...this.prepareFormData}
                    if(this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.put(`/news/news/${this.form.id}/update/`, formData)
                            if(data) {
                                this.resetList()
                                eventBus.$emit('update_front_news_list')
                                eventBus.$emit('update_full_news')
                                this.visible = false
                                this.$message.info('Новость обновлена')
                            }
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка создания')
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/news/news/create/', formData)
                            if(data) {
                                eventBus.$emit('update_front_news_list')
                                this.resetList()
                                this.visible = false
                                this.$message.info('Новость создана')
                            }
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка создания новости')
                        } finally {
                            this.loading = false
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        async handleFileChange(event) {
            const file = Object.values(event.target.files)[0]
            if(file) {
                try {
                    this.fileLoading = true
                    let formData = new FormData()
                    formData.append("upload", file)
                    const {data} = await this.$http.post('/common/upload/', formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    if(data?.length) {
                        this.form.image = data[0];
                        this.customFile = true;
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.fileLoading = false
                }
            }
        },
        clearFile() {
            this.form.image = null
            this.customFile = false
            this.rules.template = [
                { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
            ]
            this.rules.contentImage = [
                { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
            ]
            this.resetFileInput()
        },
        resetFileInput() {
            const input = this.$refs.image_upload
            if (input) {
                input.type = 'text'
                input.type = 'file'
            }
        }
    }
}
</script>
