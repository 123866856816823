<template>
    <ModuleWrapper :pageTitle="pageTitle">
        <template 
            v-slot:h_right>
            <a-button
                v-if="userHasPermissions"
                type="primary"
                :ghost="isMobile"
                size="large"
                class="w-full"
                @click="openCreate()">
                Добавить новость
            </a-button>
        </template>
        <a-collapse 
            v-if="windowWidth < 1200"
            class="mb-4"
            :bordered="false"
            :expand-icon-position="expandIconPosition">
            <a-collapse-panel 
                header="Фильтр">
                <news-filter
                    :onCheckCategory="checkCategory"
                    :onCheckWorkgroup="checkWorkgroup"
                    :filterLoader="filterLoader"
                    :collapseMode="true"/>
            </a-collapse-panel>
        </a-collapse>
        <div class="news_container" ref="news_container" :class="windowWidth >= 1200 && 'news-layout'">
            <div class="news_list">
                <div v-if="listEmpty && !loading">
                    <a-empty description="Нет новостей" />
                </div>
                <NewsCard 
                    v-for="news in list.results" 
                    :news="news" 
                    :key="news.id" />
                <infinite-loading 
                    ref="infiniteLoading"
                    @infinite="getList"
                    :identifier="infiniteId"
                    :distance="10">
                    <div 
                        slot="spinner"
                        class="flex items-center justify-center inf_spinner">
                        <a-spin />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
            <news-filter
                v-if="windowWidth >= 1200"
                :onCheckCategory="checkCategory"
                :filterLoader="filterLoader"
                :onCheckWorkgroup="checkWorkgroup"
                class="w-[300px] news_aside_filters"/>
        </div>
        <create-drawer ref="createDrawer"/>
    </ModuleWrapper>
</template>

<script>
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
import CreateDrawer from '@/modules/News/CreateDrawer.vue'
import NewsFilter from '@/modules/News/NewsFilter.vue'
import NewsCard from './NewsCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import eventBus from "@/utils/eventBus"
import { mapState } from 'vuex'

export default {
    components: {
        ModuleWrapper,
        CreateDrawer,
        NewsFilter,
        NewsCard,
        InfiniteLoading
    },
    data() {
        return {
            expandIconPosition: 'right',
            isFilterVisible: true,
            is_staff: null,
            is_support: null,
            infiniteId: new Date(),
            loading: false,
            listEmpty: false,
            filterLoader: false,
            options: {
                topSpacing: 20
            },
            list: {
                next: true,
                results: [],
                count: 0,
                page: 0
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            windowWidth: state => state.windowWidth
        }),
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        userHasPermissions() {
            if(this.user)
                return this.user.is_staff || this.user.is_support
            else
                return null
        }
    },
    methods: {
        reloadList() {
            if(this.isMobile) {
                window.scroll({top: 0, behavior: "smooth"})
            } else {
                document.querySelector('.m_wrapper__body').scroll({top: 0, behavior: "smooth"})
            }
            if(this.listEmpty)
                this.listEmpty = false

            this.loading = false
            this.list.page = 0
            this.list.next = true
            this.list.results = []
            this.$nextTick(() => {
                this.infiniteId = new Date()
                this.$refs['infiniteLoading'].stateChanger.reset()
            })
        },
        async getList($state) {
            if(this.list.next) {
                if(!this.loading) {
                    try {
                        this.loading = true
                        this.list.page += 1
                        const { data } = await this.$http.get('/news/news/list/', {
                            params: {
                                page: this.list.page,
                                page_size: 15
                            }
                        })
                        
                        if(data.results?.length) {
                            this.list.results = this.list.results.concat(data.results)
                        }

                        this.list.next = data.next

                        if(this.list.page === 1 && !data.results?.length) {
                            this.listEmpty = true
                        }

                        if (!data.next) {
                            $state.complete()
                        } else {
                            $state.loaded()
                        }
                    } catch (error) {
                        console.log(error, 'error')
                        this.$message.error('Ошибка')
                    } finally {
                        this.loading = false
                    }
                }
            } else {
                $state.complete()
                this.loading = false
            }
        },
        openCreate() {
            this.$nextTick(() => {
                this.$refs.createDrawer.openDrawer()
            })
        },
        async checkCategory(id, event) {
            const formData = { type: "categories", id: id };
            try {
                this.filterLoader = true
                if (event.target.checked) {
                    await this.$http.post('/news/news/filters/check/', formData);
                } else {
                    await this.$http.post('/news/news/filters/uncheck/', formData);
                }
                this.reloadList()
            } catch(e) {
                console.log(e);
            } finally {
                this.filterLoader = false
            }
        },
        async checkWorkgroup(id, event) {
            const formData = { type: "workgroups", id: id };
            try {
                this.filterLoader = true
                if (event.target.checked) {
                    await this.$http.post('/news/news/filters/check/', formData);
                } else {
                    await this.$http.post('/news/news/filters/uncheck/', formData);
                }
                this.reloadList();
                
            } catch(e) {
                console.log(e);
            } finally {
                this.filterLoader = false
            }
        }
    },
    mounted() {
        eventBus.$on('update_front_news_list', () => {
            this.reloadList()
        })
    },
    beforeDestroy() {
        eventBus.$off('update_front_news_list')
    }
}
</script>

<style lang="scss" scoped>
.news-layout {
    display: block;
    @media (min-width: 992px) {
        display: grid;
        grid-template-columns: 1fr 300px;
        column-gap: 16px;
    }
}
.news-layout-mobile {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    ::v-deep .h_right {
        width:100%
    }
    ::v-deep .m_wrapper__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 105px;
    }
}

::v-deep .ant-collapse > .ant-collapse-item {
    border: none
}
.news_aside_filters{
    &::v-deep{
        .ant-form{
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
    }
}
</style>

<style lang="scss">
.ant-collapse-header{
    font-size: 16px;
    color: #000;
}
.ant-collapse-borderless{
    background-color: #f1f2f5;
    .ant-card-body,
    .ant-card{
        padding: 0px!important;
    }
    .ant-form{
        &:not(:last-child){
            margin-bottom: 15px;
        }
    }
}
</style>