<template>
    <div class="news_card">
        <figure class="news_img" @click="$router.push({ name: 'news-full', params: { id: news.id } })">
            <picture>
                <img :data-src="news.image && news.image.path" :alt="news.title" class="lazyload" />
            </picture>
        </figure>
        <div class="news_content">
            <div class="news_content__info">
                <div class="mb-3 flex items-center justify-between">
                    <a-button v-if="news.category" type="success" ghost class="cursor-default">
                        <template v-if="isGroup">
                            {{ news.work_groups[0].name }}
                        </template>
                        <template v-else>
                            {{ news.category.name }}
                        </template>
                    </a-button>
                    <div class="news_date">{{ $moment(news.created_at).format('DD.MM.YYYY') }}</div>
                </div>
                <h2 @click="$router.push({ name: 'news-full', params: { id: news.id } })">
                    <span v-if="news.is_important" class="important">
                        <img src="./assets/img/fire.svg" />
                    </span>
                    {{ news.title }}
                </h2>
                <div class="body_text">{{ news.short_content }}</div>
            </div>
            <div class="mt-3 flex items-center justify-between">
                <router-link :to="{ name: 'news-full', params: { id: news.id } }" class="more_news">читать</router-link>
                <div class="flex items-center like_actions">
                    <a-badge :count="taskVote.likes_count" :number-style="{ backgroundColor: '#52c41a' }">
                        <a-button 
                            type="ui" 
                            flaticon 
                            shape="circle"
                            icon="fi-rr-social-network" 
                            :class="{ 'blue_color': myVote === 'like'}" 
                            @click="vote('like')" />
                    </a-badge>
                    <a-badge :count="taskVote.dislikes_count">
                        <a-button 
                            type="ui" 
                            class="ml-2"
                            flaticon 
                            shape="circle"
                            icon="fi-rr-hand" 
                            :class="{ 'text_red': myVote === 'dislike'}"
                            @click="vote('dislike')" />
                    </a-badge>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        news: {
            type: Object,
            required: true
        }
    },
    computed: {
        myVote() {
            if(this.taskVote.my_vote === null)
                return null
            if(this.taskVote.my_vote)
                return 'like'
            return 'dislike'
        },
        isGroup() {
            return this.news.work_groups?.[0]?.id
        }
    },
    data() {
        return {
            taskVote: {},
            taskVoteLoading: false,
        }
    },
    created() {
        this.getVote()
    },
    methods: {
        async vote(choice) {
            let boolChoice,
                fieldToVote,
                oppositeFieldToVote
            if (choice === 'like') {
                fieldToVote = 'likes_count'
                oppositeFieldToVote = 'dislikes_count'
                boolChoice = true
            } else if (choice === 'dislike') {
                fieldToVote = 'dislikes_count'
                oppositeFieldToVote = 'likes_count'
                boolChoice = false
            }
            const payload = {
                vote: boolChoice
            }

            await this.$http.post(`vote/${this.news.id}/`, payload)
                .then(() => {
                    if(this.taskVote.my_vote !== null) {
                        if(this.taskVote.my_vote === boolChoice) {
                            this.taskVote[fieldToVote] += -1
                            this.taskVote.my_vote = null
                        } else {
                            this.taskVote[oppositeFieldToVote] += -1
                            this.taskVote[fieldToVote] += 1
                            this.taskVote.my_vote = boolChoice
                        }
                    } else {
                        this.taskVote[fieldToVote] += 1
                        this.taskVote.my_vote = boolChoice
                    }
                })
                .catch(error => console.error(error))
        },
        async getVote() {
            try {
                this.taskVoteLoading = true
                const { data } = await this.$http.get(`vote/${this.news.id}/`)
                this.taskVote = data
            } catch(e) {
                console.log(e)
            } finally {
                this.taskVoteLoading = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.like_actions{
    &::v-deep{
        .ant-badge-count{
            min-width: 15px;
            height: 15px;
            line-height: 15px;
            top: 4px;
            right: 2px;
            font-size: 10px;
            &.ant-badge-multiple-words{
                padding: 0 4px;
            }
            .ant-scroll-number-only{
                height: 15px;
                p{
                    height: 15px;
                }
            }
        }
        .ant-badge-count{
            font-size: 10px !important;
            min-width: 17px;
            height: 17px;
            padding: 0 6px;
            line-height: 17px;
        }   
    }
}
.news_card{
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 15px;
    &:not(:last-child){
        margin-bottom: 15px;
    }
    @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        padding: 30px;
    }
    .body_text{
        font-size: 16px;
        line-height: 22px;
        opacity: 0.7;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.3s;
        word-break: break-word;
        @media (min-width: 992px) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .news_img{
        background: rgba(0, 0, 0, 0.04);
        height: 200px;
        overflow: hidden;
        border-radius: 15px;
        margin-bottom: 15px;
        width: 100%;
        cursor: pointer;
        @media (min-width: 992px) {
            margin-bottom: 0px;
            margin-right: 15px;
            min-width: 200px;
            width: 200px;
            height: 150px;
        }
        @media (min-width: 1200px) {
            min-width: 270px;
            width: 270px;
            height: 200px;
        }
        @media (min-width: 1400px) {
            margin-right: 30px;
            margin-bottom: 0px;
            min-width: 370px;
            width: 370px;
        }
        img{
            object-fit: cover;
            -o-object-fit: cover;
            vertical-align: middle;
            transition: opacity .15s ease-in-out;
            width: 100%;
            opacity: 0;
            &.lazyloaded{
                opacity: 1;
            }
        }
    }
    .news_content{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        &__info{
            flex-grow: 1;
        }
    }
    h2{
        font-size: 18px;
        line-height: 24px;
        color: #000;
        font-weight: 400;
        margin-bottom: 15px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: color .15s ease-in-out;
        @media (min-width: 992px) {
            font-size: 20px;
            line-height: 26px;
        }
        &:hover{
            color: var(--blue);
        }
    }
    .important{
        max-width: 18px;
        min-width: 18px;
        margin-right: 10px;
        display: inline-block
    }
    .news_date{
        color: #000;
        opacity: 0.6;
    }
    .more_news{
        color: #0A70CF;
    }
}
</style>