<template>
    <div>
        <a-spin :spinning="filterLoader">
            <a-card 
                class="filter-widget"
                :class="{ collapse: collapseMode }">
                <h2 v-if="!collapseMode" class="font-medium" style="font-size: 20px;margin-bottom: 10px;">Фильтр</h2>
                <div v-show="filterLoading" class="flex justify-center my-4">
                    <a-spin/>
                </div>
                <div v-show="!filterLoading">
                    <a-collapse v-model="activeKey" :bordered="false">
                        <a-collapse-panel key="1" header="По категориям">
                            <a-form-model>
                                <a-checkbox-group v-model="selectedCategories">
                                    <a-checkbox 
                                        v-for="item in categories" 
                                        :key="item.id" 
                                        :value="item.id" 
                                        class="checkbox-label" 
                                        @change="onCheckCategory(item.id, $event)">
                                        {{ item.name }}
                                    </a-checkbox>
                                </a-checkbox-group>
                            </a-form-model>
                        </a-collapse-panel>
                        <a-collapse-panel key="2" header="По командам">
                            <a-form-model>
                                <a-checkbox-group v-model="selectedWorkgroups">
                                    <a-checkbox 
                                        v-for="item in workgroups" 
                                        :key="item.id" 
                                        :value="item.id" 
                                        class="checkbox-label" 
                                        @change="onCheckWorkgroup(item.id, $event)">
                                        {{ item.name }}
                                    </a-checkbox>
                                </a-checkbox-group>
                            </a-form-model>
                        </a-collapse-panel>
                    </a-collapse>
                </div>
            </a-card>
        </a-spin> 
    </div>
</template>

<script>
export default {
    props: {
        onCheckCategory: {
            type: Function,
            default: () => {}
        },
        onCheckWorkgroup: {
            type: Function,
            default: () => {}
        },
        filterLoader: {
            type: Boolean,
            default: false
        },
        collapseMode: Boolean
    },
    data () {
        return {
            activeKey: ['1', '2'],
            categories: [],
            workgroups: [],
            selectedCategories: [],
            selectedWorkgroups: [],
            filterLoading: false
        }
    },
    created() {
        this.filter();
    },
    methods: {
        async filter() {
            try {
                this.loading = true;
                this.filterLoading = true;
                const { data } = await this.$http.get('/news/news/filters/', {
                    params: {
                        page: this.page
                    }
                });
                if(data) {
                    this.categories = data.filter(item => item.type === 'categories');
                    this.workgroups = data.filter(item => item.type === 'workgroups');
                    this.selectedCategories = this.categories.filter(item => item.checked).map(item => item.id);
                    this.selectedWorkgroups = this.workgroups.filter(item => item.checked).map(item => item.id);
                }
            } catch(e) {
                console.log(e);
            } finally {
                this.loading = false;
                this.filterLoading = false
            }
        },

    }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    padding: 6px 0px;
}

.filter-widget {
    background-color: #f1f2f5;
    border: 0px;
    &::v-deep{
        .ant-collapse-arrow{
            left: 0px!important;
        }
        .ant-collapse-header{
            padding-top: 0px;
            padding-right: 0px;
            padding-left: 20px;
            padding-bottom: 0px;
        }
        .ant-collapse-content-box{
            padding: 0px;
        }
        .ant-collapse-item{
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}
.filter-widget::-webkit-scrollbar {
    display: none;
}
.collapse {
    background: none;
    border: none;
}
</style>
